@font-face {
    font-family: "NeueMachina";
    src: url("../assets/NeueMachina-Medium.eot");
    src: url("../assets/NeueMachina-Medium.woff") format("woff"),
    url("../assets/NeueMachina-Medium.otf") format("opentype")
}

html, body {
    margin: 0;
    font-family: "NeueMachina";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}
html * {
    scrollbar-width: none;

}
::-webkit-scrollbar {
    display: none;
}
#root {
    width: 100%;
    min-height: 100%;
}
