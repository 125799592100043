div { display: flex; flex-direction: column; }
input { border-top: none; border-left: none; border-right: none; border-color: black; }
button { border: solid; border-radius: 0; border-color: black; border-width: 2px; padding: 0.25rem 0.5rem; margin: 0.25rem; background-color: white; color: black; }


.App { height: 100%; width: 100%; }
.container { max-width: 800px; width: 100%; margin: 1rem; }
.full-width { width: 100%; }
.row { flex-direction: row; flex-wrap: wrap; }
.big-button { padding: 0.8rem 2rem; margin: 2.5rem; color: black; font-size: 2rem; }
.disabled { opacity: 0.4; }
.align-center { align-self: center; }
.margin-center { margin: 0 auto; }
.align-start { align-self: flex-start; }
.align-items-center { align-items: center; }
.justify-center { justify-content: center; }
.justify-space { justify-content: space-between; }

.main { height: 100%; width: 100%; }
.timer { margin: 0 1rem; }
.box { box-sizing: border-box; border: solid; border-color: black; border-radius: 0.1rem; border-width: 2px; }
.highlighted { box-shadow: 0px 3px 5px #ccc; transform: scale(1.01); }
.timer-count { text-align: center; margin: 1.5rem }
.list-item { flex-direction: row; justify-content: space-between; padding: 0 1rem; margin: 0.5rem; }
.complete-tick { height: 1.2rem; align-self: center; }
.highlight { transform: scale(1.025); box-shadow: 0px 2px 2px #eaeaea; }

.media-info { width: 720px; margin: 0 auto; margin-bottom: 1rem; }
.video-player { position: relative; width: 720px; height: 405px; z-index: 0; }
.fullscreen {position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; z-index: 100; }

.data-buttons { position: fixed; right: 0; height: 100%; width: 18%; margin-right: 2rem; }
@media only screen and (max-width: 1024px) {
    .data-buttons { margin: auto; padding: 2rem; position: relative; width: 300px; }
}
